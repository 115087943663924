<template>
    <div class="browser" :class="['view-' + view, theme, {'translucent': translucent, 'white': !translucent, 'scrollable': scrollable, 'fullscreen': fullscreen, 'no-rounded-border': noRoundedBorder}]">
        <div v-if="!noActions || !noSearchBar || title !== null" class="browser-header" :class="[headerClass, headerVariant ? 'bg-' + headerVariant : '' ]">
            <div class="search" v-if="!noSearchBar" :disabled="!items || items.length === 0">
                <b-input v-model="search" maxlength="50" autocomplete="off" :autofocus="searchAutofocus" debounce="150"
                         :placeholder="['Quick search', 'Recherche rapide'] | tr"
                         :class="{'translucent': translucent}"/>
                <i v-show="!search" class="fas fa-search"></i>
                <i @click="search = ''" v-show="search" class="fas fa-times"></i>
            </div>

            <b-input-group v-if="!noOptions && !isMobileLayout && typeof this.items !== 'function'" class="right-actions float-right d-inline-flex w-auto"
                           style="height: 34px;">
                <b-dropdown :variant="headerButtonVariant">
                    <template v-slot:button-content>
                        <span v-tr>Options|Options</span>
                    </template>
                    <template v-if="isArchiveEnabled || isTrashEnabled">
                        <b-dropdown-item @click="filters.archived = false, filters.deleted = false"
                                         :disabled="!filters.archived && !filters.deleted">
                            <i class="fas fa-folder-open"></i>
                            <span v-tr>All items|Tous les éléments</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="isArchiveEnabled"
                                         @click="filters.archived = !filters.archived, filters.deleted = false"
                                         :disabled="filters.archived">
                            <i class="fas fa-archive"></i>
                            <span v-tr>Items Archived|Éléments archivés</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="isTrashEnabled && showTrash"
                                         @click="filters.deleted = !filters.deleted, filters.archived = false"
                                         :disabled="filters.deleted">
                            <i class="fas fa-trash"></i>
                            <span v-tr>Open trash|Ouvrir la corbeille</span>
                        </b-dropdown-item>
                    </template>
                    <b-dropdown-divider v-if="isTrashEnabled || isArchiveEnabled"></b-dropdown-divider>
                    <template v-if="typeof items !== `function`">
                        <b-dropdown-item @click="exportFieldsToCSV">
                            <i class="fas fa-file-csv"></i>
                            <span v-tr>Export to CSV|Exporter en CSV</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="canAdminEyeInWireless()" @click="exportRawItemsToCSV">
                            <i class="fas fa-file-csv"></i>
                            <span v-tr>Raw Export to CSV|Export brut en CSV</span>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
                <b-dropdown :variant="headerButtonVariant" v-b-tooltip :title="'Show or hide columns|Afficher ou masquer des colonnes'|tr" no-caret>
                    <template #button-content>
                        <i class="fas fa-columns fa-fw"></i>
                    </template>
                    <b-dropdown-item v-for="field in tableFields" :key="field.id" @click.prevent.stop="showColumn(field.key)">
                        <b-checkbox :checked="!field.hidden">
                            {{field.label}}
                        </b-checkbox>
                    </b-dropdown-item>
                </b-dropdown>
            </b-input-group>
            <div class="title" v-if="title && !noActions">
                <a @click="filters.archived = false, filters.deleted = false"
                   :class="{ 'interactive': filters.archived || filters.deleted }"><slot name="title">{{ title|tr }}</slot></a>
                <template v-if="!isMobileLayout">
                    <span v-if="filters.archived"><i class="fas fa-caret-right mx-1"></i> <i
                        class="fas fa-archive mr-1"></i> <span v-tr>Archive|Archive</span></span>
                    <span v-if="filters.deleted"><i class="fas fa-caret-right mx-1"></i> <i
                        class="fas fa-trash mr-1"></i> <span v-tr>Trash|Corbeille</span></span>
                </template>

                <slot class="actions" v-if="!noActions" name="actions"></slot>
            </div>
        </div>

        <div v-if="actionBar" class="action-bar">
            <slot name="action-bar"></slot>
        </div>

        <div class="browser-fields" v-if="fields && (view === 'list' || view === 'large-list')">
            <div v-for="field in fieldsToShow" @click="changeSortBy(field)" class="field" :class="field.size ? 'size-' + field.size : ''" :title="field.title" :style="field.style">
                <span class="mr-2">{{ field.label }}</span>
                <div class="ml-auto" :class="field.sortable ? 'sort-icons' : 'd-none'">
                    <i class="fas fa-caret-up d-block" :class="{ 'opacity-1': sortBy.key === field.key && sortBy.order === 'asc' }" style="margin-bottom: -4px"></i>
                    <i class="fas fa-caret-down d-block" :class="{ 'opacity-1': sortBy.key === field.key && sortBy.order === 'desc' }"></i>
                </div>
            </div>
        </div>

        <div class="browser-container">
            <drop class="browser-content" :class="[contentClass, { 'drop-visible': dropVisible }]" @drop="onItemDropped" @dragstart="dropVisible = true" @dragover="dropVisible = true" @dragleave="dropVisible = false">
                <div class="drop-overlay">
                    <span v-tr>Drop Here|Déposez ici</span>
                </div>
                <h4 v-if="search"><span v-tr>Result for|Résultats pour </span> {{ search }}</h4>
<!--                <component v-if="defaultSelected && showDefault" :is="itemComponentTag(defaultSelected)" :draggable="draggable" name-field="current" :fields="tableFields"-->
<!--                           :icon="icon" :thumbnail="itemThumbnail(defaultSelected)" :avatar="avatar"-->
<!--                           :theme="theme" :view="view" :item="defaultSelected" :key="`value-item`"-->
<!--                           @item-selected="onItemSelected" @open-item="onItemOpened"-->
<!--                           @right-click="onOpenContextMenu" @item-dragged="onItemDragged"-->
<!--                           @item-dragend="onItemDragEnd"-->
<!--                           class="selected-default" :translate="translate">-->
<!--                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">-->
<!--                        <slot :name="name" v-bind="slotData"/>-->
<!--                    </template>-->
<!--                </component>-->
                <template v-for="(group, groupIndex) in groups">
                    <template v-if="!search && groupBy && groupCount > 1">
                        <e-browser-category :theme="theme" :view="view" :name="group.name" :key="groupIndex">
                            <template #name>
                                <slot name="group" :group="group">{{group.name}}</slot>
                            </template>
                            <template v-for="(item, index) in group.items">
                                <component :is="itemComponentTag(item)" :draggable="draggable" :name-field="nameField" :fields="tableFields"
                                           :icon="icon" :thumbnail="itemThumbnail(item)" :avatar="avatar"
                                           :theme="theme" :view="view" :item="item" :key="groupIndex+'-'+index"
                                           @item-selected="onItemSelected" @open-item="onItemOpened"
                                           @right-click="onOpenContextMenu" @item-dragged="onItemDragged"
                                           @item-dragend="onItemDragEnd"
                                           :class="{'selected': value === item}" :translate="translate">
                                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                                        <slot :name="name" v-bind="slotData"/>
                                    </template>
                                </component>
                            </template>
                        </e-browser-category>
                    </template>
                    <template v-else-if="groupBy">
                        <e-browser-category :theme="theme" :view="view" :name="group.name" :key="`g-${groupIndex}`">
                            <template #name>
                                <slot name="group" :group="group">{{group.name}}</slot>
                            </template>
                            <template v-for="(item, index) in group.items">
                                <component :is="itemComponentTag(item)" :draggable="draggable" :name-field="nameField" :fields="tableFields"
                                           :icon="icon" :thumbnail="itemThumbnail(item)" :avatar="avatar"
                                           :theme="theme" :view="view" :item="item" :key="`i-${groupIndex}-${index}`"
                                           @item-selected="onItemSelected" @open-item="onItemOpened"
                                           @right-click="onOpenContextMenu" @item-dragged="onItemDragged"
                                           @item-dragend="onItemDragEnd"
                                           :class="{'selected': value === item}" :translate="translate">
                                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                                        <slot :name="name" v-bind="slotData"/>
                                    </template>
                                </component>
                            </template>
                        </e-browser-category>
                    </template>
                    <template v-else v-for="(item, index) in group.items">
                        <component :is="itemComponentTag(item)" :draggable="draggable" :name-field="nameField" :fields="tableFields"
                                       :icon="icon" :thumbnail="itemThumbnail(item)" :avatar="avatar"
                                       :theme="theme" :view="view" :item="item" :key="`i-${groupIndex}-${index}`"
                                       @item-selected="onItemSelected" @open-item="onItemOpened"
                                       @right-click="onOpenContextMenu" @item-dragged="onItemDragged"
                                       @item-dragend="onItemDragEnd"
                                       :class="{'selected': value === item}" :translate="translate">
                                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                                    <slot :name="name" v-bind="slotData"/>
                                </template>
                            </component>
                    </template>
                </template>
            </drop>
        </div>

        <component v-model="contextVisible" :is="isMobileLayout ? 'b-sidebar': 'vue-context'" ref="menu"
                   class="browser-context-menu" backdrop shadow>
            <template v-if="itemSelected">
                <component :is="componentTag" v-if="isMobileLayout" view="icons" :name-field="nameField" :fields="tableFields"
                           :icon="icon" :thumbnail="itemThumbnail(itemSelected)" :ävatar="avatar"
                           :theme="theme" standalone :item="itemSelected">
                    <template #item>
                        <slot name="item" :item="itemSelected" :view="view"></slot>
                    </template>
                    <template #list-item>
                        <slot name="list-item" :item="itemSelected" :view="view"></slot>
                    </template>
                </component>
                <slot name="context" :item="itemSelected"></slot>

                <template v-if="contextAuto">
                    <b-dropdown-item @click="onItemOpened" :to="openItemSelectedRoute(itemSelected)">
                        <i class="fas fa-info"></i>
                        <span v-tr>Properties|Propriétés</span>
                    </b-dropdown-item>
                </template>

                <slot name="context-after" :item="itemSelected"></slot>

                <template v-if="isArchiveEnabled || isTrashEnabled">
                    <b-dropdown-divider v-if="$slots.context || contextAuto"/>
                    <template v-if="!itemSelected.archived && !itemSelected.deleted">
                        <li v-if="isArchiveEnabled">
                            <e-button-confirm class="text-warning" element-tag="a"
                                              @confirmed="archiveOrRestore"
                                              :icon="getPublicIcon('box')"
                                              :confirm-text="`Do you really want to archive ${tr(itemSelected.name)}? You can view and restore your archived items in the options.|Voulez-vous vraiment archiver ${tr(itemSelected.name)}? Vous pouvez voir et restaurer vos éléments archivés depuis les options.`"
                                              confirm-title="Archive|Archiver" button-class="btn-warning"
                                              button-text="Archive|Archiver">
                                <i class="fas fa-archive"></i>
                                <span v-tr>Archive item|Archiver l'élément</span>
                            </e-button-confirm>
                        </li>
                        <!--                        <b-dropdown-divider v-if="isTrashEnabled && isArchiveEnabled"></b-dropdown-divider>-->
                        <li v-if="isTrashEnabled">
                            <e-button-confirm class="text-danger" @confirmed="deleteOrRestore" element-tag="a"
                                              :icon="getPublicIcon('trash')"
                                              :confirm-text="`Do you really want to delete ${tr(itemSelected.name)}?|Voulez-vous vraiment supprimer ${tr(itemSelected.name)}?`"
                                              confirm-title="Delete|Suppression" button-class="btn-danger"
                                              button-text="Delete|Supprimer">
                                <i class="fas fa-trash"></i>
                                <span v-tr>Delete item|Supprimer l'élément</span>
                            </e-button-confirm>
                        </li>
                    </template>
                    <b-dropdown-item v-if="itemSelected.archived" @click="archiveOrRestore">
                        <i class="fas fa-trash-restore"></i>
                        <span v-tr>Restore from archive|Retirer de l'archive</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-else-if="itemSelected.deleted" @click="deleteOrRestore">
                        <i class="fas fa-trash-restore"></i>
                        <span v-tr>Restore from trash|Retirer de la corbeille</span>
                    </b-dropdown-item>
                </template>
            </template>
        </component>

        <div v-if="!noFooter" class="browser-footer" :class="footerClass">
            <btn-view v-model="view" v-if="!forcedView"></btn-view>
            <slot name="item-count">
                <span class="f-500">{{ itemDisplayCount }} <span v-tr>items|éléments </span></span>
            </slot>
            <b-pagination :class="{ 'translucent': translucent }" v-if="totalItemLength > perPage" v-model="currentPage"
                          :total-rows="totalItemLength" :per-page="perPage"/>
        </div>
    </div>
</template>

<script>
import BrowserHelper from "../helpers/BrowserHelper.js";
import {getNestedValue, download} from "../helpers/utils.js";
import BtnView from './btn-view.vue';
import {compareTranslation} from "../helpers/i18n";
import Network from "../helpers/Network";
import EBrowserCategory from "./e-browser-category.vue";
import EBrowserItem from "./e-browser-item.vue";
import EButtonConfirm from "./e-button-confirm.vue";
import {VueContext} from "vue-context"
import {Drop} from "vue-drag-drop"

/*
Documentation available here
https://github.com/Eye-InMedia/vue-components/wiki/e-browser
 */

// e-browser
export default {
    name: `e-browser`,
    components: {
        Drop,
        EBrowserItem,
        "vue-context": VueContext,
        EButtonConfirm,
        EBrowserCategory,
        BtnView
    },
    props: {
        value: {type: Object},
        defaultId: {type: String},
        items: {type: [Array, Function]},
        count: {type: Number},
        defaultPage: {type: Number, default: 1},
        perPage: {type: Number, default: Infinity},
        defaultView: {type: String, default: `icons`}, // small-icons, icons, large-icons, list, large-list
        forcedView: {type: String},
        title: {type: String, default: null},
        thumbnail: {type: [String, Function]},
        icon: {type: [String, Function]},
        avatar: {type: Boolean}, // this will round the icon
        fullscreen: { type: Boolean },
        fields: {
            type: Array,
            default: () => [
                {key: `name`, label: `Name`}
            ]
        },
        componentTag: {type: [String, Function], default: `e-browser-item`},
        translucent: {type: Boolean, default: true},
        contextAuto: {type: Boolean, default: false},
        actionBar: {type: Boolean},
        scrollable: {type: Boolean, default: true},
        enableTrash: {type: Boolean, default: false},
        showTrash: {type: Boolean, default: false},
        enableArchive: {type: Boolean, default: false},
        searchAutofocus: {type: Boolean, default: false},
        disableSortByName: {type: Boolean, default: false},
        footerClass: { type: String, default: `` },
        contentClass: { type: String, default: `` },
        headerClass: { type: String, default: `` },
        headerVariant: { type: String, default: null },
        noActions: {type: Boolean},
        noSearchBar: {type: Boolean},
        noOptions: {type: Boolean},
        noFooter: {type: Boolean, default: false},
        searchKeys: {type: Array},
        draggable: {type: Boolean, default: false},
        noRoundedBorder: {type: Boolean, default: false},
        groupBy: {type: String, default: null},
        nameField: {type: String, default: `name`},
        translate: {type: Boolean, default: false},
        theme: {type: String, default: `dark`},
        editRoute: {type: String, default: null},
        archiveOrRestoreUrl: {type: String, default: null},
        deleteOrRestoreUrl: {type: String, default: null},
        apiOptions: {type: Object, default: () => {}},
        showDefault: {type: Boolean}
    },
    data() {
        return {
            defaultSelected: null,
            loading: true,
            currentItems: [],
            tableFields: [],
            currentPage: 0,
            totalItemLength: null,
            view: `icons`,
            search: ``,
            currentNode: null,
            contextVisible: false,
            filters: {
                deleted: false,
                archived: false
            },
            dropVisible: false,
            itemSelected: null,
            sortBy: {
                key: null,
                type: null,
                order: null
            }
        }
    },
    computed: {
        fieldsToShow() {
            if (this.tableFields) {
                return this.tableFields.filter(f => !f.hidden && !f.hiddenBrowser);
            } else {
                return this.tableFields;
            }
        },
        headerButtonVariant() {
            if (this.translucent) {
                return `transparent`;
            }
            return this.headerVariant === `light` ? `white` : `light`;
        },
        showActions() {
            return this.actions || !this.noActions;
        },
        isArchiveEnabled() {
            return this.enableArchive || this.archiveOrRestoreUrl;
        },
        isTrashEnabled() {
            return this.enableTrash || this.deleteOrRestoreUrl;
        },
        groups() {
            let items = this.currentItems;
            if (!items) {
                return null;
            }
            if (typeof this.items !== `function`) {
                if (this.isArchiveEnabled && items) {
                    items = items.filter(i => {
                        return (this.filters.archived && i.archived) || (!this.filters.archived && !i.archived);
                    });
                }
                if (this.isTrashEnabled && items) {
                    items = items.filter(i => {
                        return (this.filters.deleted && i.deleted) || (!this.filters.deleted && !i.deleted);
                    });
                }
            }
            if (this.search && typeof this.items !== `function`) {
                // Only exact match, otherwise it returns garbage too often
                let matchingItems = [];
                let search = new RegExp(this.search, `gi`);
                for (let item of items) {
                    let added = false;
                    for (let key of this.searchableKeys) {
                        let nestedValue = getNestedValue(item, key);
                        if (nestedValue !== undefined) {
                            if (typeof nestedValue === `object`) {
                                for (let k in nestedValue) {
                                    if (search.test(nestedValue[k])) {
                                        matchingItems.push(item);
                                        added = true;
                                        break;
                                    }
                                }
                            } else if (search.test(nestedValue)) {
                                added = true;
                                matchingItems.push(item);
                                break;
                            }
                        }
                        if (added) {
                            break;
                        }
                    }
                }
                items = matchingItems;

                /* Removing Fuse search for now */
                // const options = {
                //     shouldSort: true,
                //     threshold: 0.2,
                //     location: 0,
                //     distance: 100,
                //     maxPatternLength: 32,
                //     minMatchCharLength: 1,
                //     keys: this.searchableKeys
                // };
                // let fuse = new Fuse(this.items, options);
                // items = fuse.search(this.search);
                if (this.perPage < Infinity) {
                    items = items.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
                }
            }
            if (!this.disableSortByName) {
                items.sort(this.sortItem);
            }

            if (this.sortBy.key) {
                const key = this.sortBy.key;
                items.sort((a, b) => {
                    const item1 = this.sortBy.order === `desc` ? b : a;
                    const item2 = this.sortBy.order === `desc` ? a : b;

                    if (this.sortBy.type === `string`)
                        return (item1[key] || ``).localeCompare((item2[key] || ``));
                    else if (this.sortBy.type === `number`)
                        return item1[key] - item2[key];
                    else if (this.sortBy.type === `translation`)
                        return this.tr(item1[key]).localeCompare(this.tr(item2[key]));
                    else if (this.sortBy.type === `date`)
                        return new Date(item1[key]) - new Date(item2[key]);
                    else
                        return item1[key] > item2[key] ? 1 : -1;
                });
            }

            if (this.groupBy) {
                let groups = {};
                for (let item of items) {
                    if (groups[item[this.groupBy]] === undefined) {
                        groups[item[this.groupBy]] = {
                            name: item[this.groupBy],
                            items: [item]
                        };
                    } else {
                        groups[item[this.groupBy]].items.push(item);
                    }
                }
                return groups;
            } else {
                return {
                    all: {items: items}
                };
            }
        },
        groupCount() {
            if (this.groups) {
                return Object.keys(this.groups).length;
            } else {
                return 0;
            }
        },
        itemDisplayCount() {
            if (this.groups) {
                let count = 0;
                for (let key in this.groups) {
                    count += this.groups[key].items.length;
                }
                return count;
            } else {
                return 0;
            }
        },
        searchableKeys() {
            if (this.searchKeys && this.searchKeys.length > 0) {
                return this.searchKeys;
            } else if (this.fields) {
                const commonKeys = [`name`, `description`, `dealerid`, `first_name`, `last_name`, `phone_number`, `email`];
                return [...commonKeys, ...this.fields.filter(f => f.searchable || f.key === this.nameField).map(f => f.key)];
            } else {
                return [`name`, `description`];
            }
        }
    },
    mounted() {
        this.tableFields = this.fields;
        this.view = this.forcedView ? this.forcedView : this.defaultView;
        if (this.isMobileLayout && this.view === `icons`) {
            this.view = `small-icons`;
        }

        this.currentPage = this.defaultPage;

        this.$refs.menu.$el.addEventListener(`click`, evt => {
            // To close mobile sidebar menu on click
            if (!this.isMobileLayout) {
                return;
            }
            if (evt.target.tagName === `A` ||
                (evt.target.parentElement && evt.target.parentElement.tagName === `LI`)) {
                this.contextVisible = false;
            }
        });
        this.setDefaultSelected(this.value);
    },
    methods: {
        async getItems() {
            if (typeof this.items === `function`) {
                let pagination = {
                    limit: this.perPage,
                    count: this.perPage,
                    offset: Math.max((this.currentPage - 1) * this.perPage, 0),
                    page: this.currentPage,
                    search: this.search,
                    fields: this.searchableKeys
                };

                const requestResult = await this.items(pagination);
                this.currentItems = requestResult.results;
                this.totalItemLength = requestResult.count;
            } else if (this.perPage < Infinity) {
                this.currentItems = this.items.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
                if (this.count) {
                    this.totalItemLength = this.count;
                } else {
                    this.totalItemLength = getLiteralLength(this.items);
                }
            } else {
                this.currentItems = this.items;
                if (this.count) {
                    this.totalItemLength = this.count;
                } else {
                    this.totalItemLength = getLiteralLength(this.items);
                }
            }

            this.loading = false;
        },
        onItemSelected(item, event) {
            this.$emit(`input`, item, event);
        },
        onItemOpened(item, event) {
            this.$emit(`item-opened`, item, event);

            let route = this.openItemSelectedRoute(item);
            if (route) {
                this.$router.push(route);
            }
        },
        onItemDragged(item, event) {
            this.$emit(`item-dragged`, item, event);
        },
        onItemDragEnd(item, event) {
            this.$emit(`item-drag-end`, item, event);
        },
        onItemDropped(item, event) {
            this.dropVisible = false;
            this.$emit(`item-changed`, item, event);
            this.$emit(`item-dropped`, item, event);
        },
        onOpenContextMenu(evt, item) {
            if (!this.$scopedSlots[`context`] && !this.isArchiveEnabled && !this.isTrashEnabled && !this.contextAuto) {
                this.$emit(`right-click`, evt, item);
                return;
            }

            this.itemSelected = item;
            if (this.isMobileLayout) {
                this.contextVisible = true;
            } else {
                this.$refs.menu.open(evt);
            }
            this.$emit(`right-click`, evt, item);
        },
        setDefaultSelected(val) {
            if (!val) {
                return;
            }
            this.defaultSelected = {...val};
            this.defaultSelected.current = `${this.defaultSelected.name} ${this.tr(`(current)||(actuel)`)}`;
        },
        reload() {
            this.getItems();
        },
        exportFieldsToCSV() {
            this.exportCSV(this.fields);
        },
        exportRawItemsToCSV() {
            let fields = [];
            for (let key in this.items[0]) {
                fields.push({
                    key: key
                });
            }

            this.exportCSV(fields);
        },
        exportCSV(fields) {
            if (typeof this.items === `function`) {
                swal(this.tr(`Can't export|Export non disponible`), this.tr(`Export to Excel disable on asynchronous items|`), `warning`);
                return;
            }

            if (this.items.length === 0) {
                swal(this.tr(`Can't export|Export non disponible`), this.tr(`Nothing to export|Il n'y a aucun élément à exporter`), `warning`);
                return;
            }

            const sep = `;`;

            let csv = `SEP=${sep}\n`;
            for (let field of fields) {
                if (field.label) {
                    csv += field.label;
                } else {
                    csv += field.key;
                }

                csv += sep;
            }

            for (let item of this.items) {
                csv += `\n`;
                for (let field of fields) {
                    let nestedValue = BrowserHelper.getFormattedValue(item, field, `export`);
                    if (nestedValue !== undefined) {
                        if (field.key === `name` && this.translate) {
                            nestedValue = this.tr(nestedValue);
                        }

                        csv += `"${nestedValue}"`;
                    }
                    csv += sep;
                }
            }

            download(`${this.title || `MyCSV`}.csv`, csv);
        },
        sortItem(a, b) {
            if (a.id === this.defaultId) return -1;
            if (b.id === this.defaultId) return 1;
            return compareTranslation(a.name, b.name);
        },
        archiveOrRestore() {
            this.$emit(`archive-or-restore`, this.itemSelected);
            if (this.archiveOrRestoreUrl) {
                Network.patch(this.archiveOrRestoreUrl, this.itemSelected, this.apiOptions)
                    .then(() => {
                        this.$emit(`updated`);
                        let message;
                        let text;
                        let icon;
                        let theme = `dark`;
                        if (this.itemSelected.name) {
                            message = `'${this.tr(this.itemSelected.name)}'`;
                        } else {
                            message = this.tr(`The item|L'élément`);
                        }
                        if (this.itemSelected.archived) {
                            this.itemSelected.archived = false;
                            text = this.tr(`Restored|Restoré`);
                            message += this.tr(` has been restored| a été restoré`);
                            icon = getPublicIcon(`delivery`);
                            theme = `light`;
                        } else {
                            this.itemSelected.archived = true;
                            text = this.tr(`Archived|Archivé`);
                            message += this.tr(` has been archived| a été archivé`);
                            icon = getPublicIcon(`box`);
                        }

                        this.$toast.show(message, text, {
                            theme: theme,
                            timeout: 6000,
                            image: icon,
                            buttons: [
                                [
                                    this.tr(`<button>Cancel</button>|<button>Annuler</button>`),
                                    (instance, toast) => {
                                        this.archiveOrRestore();
                                        instance.hide({transitionOut: `fadeOut`}, toast, `button`);
                                    }
                                ]
                            ]
                        });
                    })
                    .catch(e => {
                        console.error(e);
                        this.showErrorMessage(`Not allowed|Non autorisé`, `You don't have permission to do this action|Vous n'avez pas la permission de faire cette action`);
                    });
            }
        },
        deleteOrRestore() {
            this.$emit(`delete-or-restore`, this.itemSelected);
            if (this.deleteOrRestoreUrl) {
                Network.patch(this.deleteOrRestoreUrl, this.itemSelected, this.apiOptions)
                    .then(() => {
                        this.$emit(`updated`);
                        let message;
                        let text;
                        let icon;
                        let theme = `dark`;
                        if (this.itemSelected.name) {
                            message = `'${this.tr(this.itemSelected.name)}'`;
                        } else {
                            message = this.tr(`The item|L'élément`);
                        }
                        if (this.itemSelected.deleted) {
                            this.itemSelected.deleted = false;
                            text = this.tr(`Restored|Restoré`);
                            message += this.tr(` has been restored| a été restoré`);
                            icon = getPublicIcon(`restore-trash`);
                            theme = `light`;
                        } else {
                            this.itemSelected.deleted = true;
                            text = this.tr(`Deleted|Supprimé`);
                            message += this.tr(` has been deleted| a été supprimé`);
                            icon = getPublicIcon(`trash`);
                        }

                        this.$toast.show(message, text, {
                            theme: theme,
                            timeout: 6000,
                            image: icon,
                            buttons: [
                                [
                                    this.tr(`<button>Cancel</button>|<button>Annuler</button>`),
                                    (instance, toast) => {
                                        this.deleteOrRestore();
                                        instance.hide({transitionOut: `fadeOut`}, toast, `button`);
                                    }
                                ]
                            ]
                        });
                    })
                    .catch(e => {
                        console.error(e);
                        this.showErrorMessage(`Not allowed|Non autorisé`, `You don't have permission to do this action|Vous n'avez pas la permission de faire cette action`);
                    });
            }
        },
        showColumn(key) {
            this.tableFields = this.tableFields.map(field => {
                field.hidden = field.key === key ? !field.hidden : field.hidden;
                return field;
            });
        },
        changeSortBy(field) {
            if (!field.sortable)
                return;

            if (this.sortBy.key !== field.key) {
                this.sortBy.key = field.key;
                this.sortBy.order = `asc`;
                this.sortBy.type = field.type;

                return;
            }

            if (!this.sortBy.order)
                this.sortBy.order = `asc`;
            else {
                if (this.sortBy.order === `asc`)
                    this.sortBy.order = `desc`;
                else
                    this.sortBy.order = null;
            }
        },
        openItemSelectedRoute(item) {
            if (!this.editRoute || !item) {
                return null;
            } else {
                let editRoute = this.editRoute;

                let params = editRoute.split(`{`);
                for (let param of params) {
                    param = param.split(`}`)[0];
                    editRoute = editRoute.replace(`{${param}}`, item[param]);
                }

                return editRoute;
            }
        },
        itemComponentTag(item) {
            if (typeof this.componentTag === `function`) {
                return this.componentTag(item);
            } else {
                return this.componentTag;
            }
        },
        itemThumbnail(item) {
            if (typeof this.thumbnail === `function`) {
                return this.thumbnail;
            } else {
                return item[this.thumbnail];
            }
        }
    },
    watch: {
        search() {
            this.getItems();
        },
        items() {
            this.getItems();
            this.setDefaultSelected(this.value);
        },
        currentPage() {
            this.getItems();
        }
    }
}
</script>

<style lang="scss" scoped>
.browser {
    background: white;
    border-radius: 5px;
    color: #555;
    font-size: 10pt;
    font-weight: 500;
    position: relative;
    user-select: none;
    display: flex;
    flex-direction: column;
    min-height: 350px;

    .browser-header {
        padding: 7px 14px 2px;
        min-height: 47px;
        flex: 1;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        &.border-bottom {
            border-bottom: 1px solid #ccc;
        }

        .search {
            float: right;
            position: relative;
            top: 0;
            right: -9px;
            width: 250px;

            @media screen and (max-width: 576px) {
                width: 100%;
                right: 0;
            }

            input {
                padding-right: 30px;
            }

            .fas {
                position: absolute;
                top: 9px;
                right: 10px;
                font-size: 16px;
                color: #aaa;

                &:hover {
                    color: #999;
                }
            }
        }

        .title {
            line-height: 30px;

            margin-right: 10px;
            text-transform: uppercase;
            letter-spacing: 1px;
            vertical-align: middle;

            a.interactive {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .custom-select {
                position: relative;
            }
        }
    }

    .action-bar,
    .browser-path {
        background: #fafafa;
        border-bottom: 1px solid #ccc;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    .browser-path {
        > a, > div {
            padding: 3px 7px;
            display: inline-block;
            border-radius: 3px;
            transition: 150ms;
            cursor: pointer;

            &:hover {
                background: #009688 linear-gradient(180deg, #26a69a, #009688) repeat-x;
                color: white !important;
            }

            & + a:before, & + div:before {
                content: "❯";
                margin-right: 10px;
                font-weight: 700;
            }
        }
    }

    .browser-fields {
        display: flex;
        width: 100%;
        padding-left: 65px;
        padding-right: 10px;
        border-bottom: 1px solid #ccc;
        text-transform: uppercase;
        letter-spacing: 1px;

        .field {
            display: flex;
            align-items: center;
            position: relative;
            flex-grow: 1;
            flex-basis: 0;
            padding: 3px 7px;
            border-right: 1px solid #ccc;

            &.size-2 {
                flex-grow: 2;
            }

            &.size-3 {
                flex-grow: 3;
            }

            &.size-4 {
                flex-grow: 4;
            }

            &.size-5 {
                flex-grow: 5;
            }

            .sort-icons {
                .fas {
                    opacity: 0.5;
                }
            }

            .opacity-1 {
                opacity: 1 !important;
            }
        }

        .field:last-child {
            border-right: none;
        }
    }

    .browser-container {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        flex: 30;

        .browser-content {
            padding: 10px;
            width: 100%;
            height: 100%;
            flex: 1;
            position: relative;

            h4 {
                padding: 5px;
                font-size: 10pt;
                margin: 3px auto 0;
                width: 300px;
                text-transform: uppercase;
                color: #565656;
                letter-spacing: 1px;
                background-color: #eee;
                border-radius: 15px;
                text-align: center;
                max-width: calc(100% - 25px);
            }

            .drop-overlay {
                position: absolute;
                left: 10px;
                top: 10px;
                right: 10px;
                bottom: 10px;
                pointer-events: none;
                border-radius: 10px;
                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.05);
                border: 2px dashed rgba(0, 0, 0, 0.2);
                transition: opacity 100ms;
                z-index: 100;
            }

            &.drop-visible {

                .drop-overlay {
                    opacity: 1;
                }
            }

            .selected-default {
                border-color: #5cd360;
                box-shadow: 0 5px 10px rgba(#5cd360, 0.5);
                transform: scale(0.96);
            }
        }
    }

    &.scrollable {
        max-height: 100%;
    }

    &.no-rounded-border {
        border-radius: 0;
    }

    .browser-footer {
        background-color: #eee;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        padding: 7px 14px;
        flex: 1;

        .btn-views {
            position: absolute;
            font-size: 9pt;
            display: inline-block;
            width: auto;
            right: 4px;
            bottom: 3px;
        }

        .pagination {
            display: inline-flex;
            margin-left: 5px;

            .page-link {
                padding: 0.25rem 0.5rem;
                font-weight: 500;
            }
        }
    }

    &.translucent {
        background: rgba(255, 255, 255, 0.1);
        color: #cce3e4;

        .browser-fields {
            border-color: rgba(255, 255, 255, 0.2);

            .field {
                border-right-color: rgba(255, 255, 255, 0.2);
            }
        }

        .drop-overlay {
            background: rgba(255, 255, 255, 0.1);
            border: 2px dashed rgba(255, 255, 255, 0.4);
        }

        .browser-footer {
            background-color: rgba(238, 238, 238, 0.1);
        }
    }

    &.view-large-list {
        .browser-fields {
            padding-left: 95px;
        }
    }

    &.fullscreen {
         display: flex;
         flex-flow: row wrap;
         flex-direction: column;
         height: 100%;

         .browser-footer,
         .browser-header {
             flex: 1;
         }

         .browser-container {
             flex: 40;
             overflow-x: hidden;
             overflow-y: auto;
             background: transparent;
         }
     }
}

@media(max-width: 576px) {
    .browser {
        .browser-header {
            .title {
                display: none;
            }
        }

        .browser-footer {
            .btn-views {
                position: relative;
                float: right;
            }
        }
    }
}
</style>
