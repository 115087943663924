<template>
    <div class="e-check-mark" @click="toggle">
        <div class="check-mark" :class="{'checked': checked, 'interactive': interactive, 'radio': radio}">
            <!--            <i class="fas fa-check text-success" v-if="checked"></i>-->
            <svg v-if="checked" height="16" viewBox="0 0 16 16" width="16"><path d="M13.305 3.28L5.993 10.6l-3.31-3.306a1 1 0 00-1.415 1.414l4.013 4.012a.997.997 0 001.414 0l8.024-8.024a1 1 0 00-1.414-1.416z"></path></svg>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: `e-check-mark`,
    props: {
        value: { type: Boolean },
        interactive: { type: Boolean },
        readonly: { type: Boolean },
        radio: { type: Boolean }
    },
    computed: {
        checked: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    methods: {
        toggle() {
            if (!this.readonly) {
                this.checked = !this.checked;
            }
            this.$emit(`click`);
        }
    }
}
</script>

<style lang="scss" scoped>
.e-check-mark {
    .check-mark {
        width: 20px;
        height: 20px;
        background: #fafafa;
        border: 1px solid #aaa;
        border-radius: 3px;
        display: inline-block;
        text-align: center;
        font-size: 22pt;
        position: relative;
        vertical-align: bottom;
        margin-right: 5px;
        cursor: default;

        &.interactive {
            cursor: pointer;
            &:hover {
                background: #ddd;
            }
        }

        &.radio {
            border-radius: 50%;
        }

        &.checked {
            border-color: var(--color-primary);
            background: var(--color-primary);

            background-image: linear-gradient(to bottom, #168ec5 0%, #087ab1 100%);
            outline: 1px solid rgba(255,255,255,.2);

            &.interactive:hover {
                background-image: linear-gradient(to bottom, #34aae0 0%, #1d9ad7 100%);
            }
        }

        svg,
        ::v-deep .fas {
            position: absolute;
            top: 1px;
            left: 1px;
            fill: white;
        }
    }
}
</style>
