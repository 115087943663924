<template>
    <div class="table-helper" :class="{ 'excel-mode': excelMode, 'compact': compact, 'fullscreen': fullscreen }">
        <div class="table-helper-header" :class="[headerClass, headerVariant ? 'bg-' + headerVariant : '' ]">
            <template v-if="excelMode">
                <div class="excel-mode-actions" v-if="excelMode">
                    <div class="excel-icon"></div>
                </div>
                <b-btn v-if="excelMode" variant="transparent" class="close-button" @click="stopExcelMode">
                    <i class="fas fa-times"/>
                </b-btn>
            </template>

            <div class="actions-bar">
                <div class="title" v-if="title || $slots.title || !hideRowCount">
                    <slot name="title">
                        {{ title|tr }}
                    </slot>

                    <b-badge v-if="!hideRowCount" class="ml-2 text-initialcase md" :variant="headerButtonVariant">
                        Total {{totalRows|number}} <span v-tr>Items|éléments</span>
                    </b-badge>
                </div>

                <div class="search" v-if="!hideSearch" :disabled="!items">
                    <slot name="search">
                        <b-input v-model="internalFilter" maxlength="50" autocomplete="off" debounce="150"
                                 :placeholder="searchPlaceholder|tr"/>
                        <i v-show="!internalFilter" class="fas fa-search"></i>
                        <i @click="internalFilter = ''" v-show="internalFilter" class="fas fa-times"></i>
                    </slot>
                </div>

                <b-input-group class="float-right">
<!--                    <template v-if="!hideSearch">-->
<!--                        <b-input-group-text>-->
<!--                            <i class="fas fa-search"></i>-->
<!--                        </b-input-group-text>-->
<!--                        <b-input type="text" autocomplete="off" v-model="internalFilter" :debounce="filterDebounce" :placeholder="searchPlaceholder|tr"></b-input>-->
<!--                    </template>-->
                    <b-select v-model="perPage" v-b-tooltip :title="'Number of items per page|Nombre d\'élément par page'|tr">
                        <option v-for="row in rowSizes" :value="row">{{row}}</option>
                        <option :value="items.length" v-tr>All ({{items.length}})|Tous ({{items.length}})</option>
                    </b-select>
                    <b-btn v-if="!hideExportCSV" @click="exportToExcel" :variant="headerButtonVariant" v-b-tooltip :title="'Export to Excel|Exporter sur Excel'|tr">
                        <i class="fas fa-file-excel fa-fw"></i>
                        <span class="ml-1" v-if="excelMode">{{exportLabel|tr}}</span>
                    </b-btn>
                    <b-dropdown :variant="headerButtonVariant" v-b-tooltip :title="'Show or hide columns|Afficher ou masquer des colonnes'|tr" no-caret>
                        <template #button-content>
                            <i class="fas fa-columns fa-fw"></i>
                        </template>
                        <b-dropdown-item v-for="field in tableFields" :key="field.id" @click.prevent.stop="showColumn(field.key)">
                            <b-checkbox :checked="!field.hidden">
                                {{field.label}}
                            </b-checkbox>
                        </b-dropdown-item>
                    </b-dropdown>

                    <b-btn v-if="!hideExcel && !excelMode" @click="startExcelMode" variant="success" v-b-tooltip :title="'Open in fullscreen|Ouvrir en plein écran'|tr">
                        <i class="fas fa-table-cells-large"></i>
                    </b-btn>

                    <slot name="actions-group"></slot>
                </b-input-group>
                <slot name="actions"></slot>

                <div class="clearfix"/>
            </div>
        </div>

        <div class="multi-selection-bar" v-if="selectable && selection && selection.length > 0">
            <slot name="multi-selection">
                <div class="multi-selection-summary">
                    <b-badge variant="primary">
                        {{selection.length}}
                    </b-badge>
                    <span class="mx-2" v-tr>Items selected|Éléments sélectionnés</span>
                    <b-btn size="sm" variant="text" @click="selectAllRows()">
                        <span v-tr>Select all {{items.length}} items|Sélectionner les {{items.length}} éléments</span>
                    </b-btn>
                </div>

                <div class="multi-selection-actions ml-auto">
                    <b-btn variant="light" class="mr-2" @click="clearSelected()">
                        <span v-tr>Cancel|Annuler</span>
                    </b-btn>
                    <slot name="multi-selection-actions" :selection="selection"></slot>
                </div>
            </slot>
        </div>

        <div class="table-helper-table-container" :class="containerClass">
            <b-table :class="tableClass" ref="tableHelper" v-if="hasAnyItems" @filtered="onFiltered" :items="items" :fields="fieldsToShow" :per-page="perPage" :striped="striped" responsive
                     :hover="hover" :select-mode="selectMode" :selectable="selectable" :small="compact" :sort-by.sync="sortBy" :sort-direction.sync="sortDirection" :sort-desc="sortDesc"
                     :selected-variant="selectedVariant" @row-selected="onRowSelected" :filter="internalFilter" :sticky-header="stickyHeader"
                     :filter-function="filterFunction" :filter-ignored-fields="filterIgnoreFields" :filter-included-fields="filterIncludedFields"
                     :sortCompare="sortCompare" :currentPage="currentPage">
                <template v-for="(_, name) in $scopedSlots" :slot="'cell('+ name + ')'" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData"/>
                </template>
                <template #head(selected)>
                    <e-check-mark class="ml-2" @click="toggleAllRows()" :value="isAllRowsSelected" readonly/>
                </template>

                <template slot="cell(selected)" slot-scope="data">
                    <e-check-mark class="mt-1" :value="isSelected(data.item)" readonly/>
                </template>
            </b-table>
            <slot name="empty" v-if="!hasAnyItems">
                <div class="table-empty">
                    <e-empty-placeholder :title="emptyTitle" :description="emptyDescription">
                    </e-empty-placeholder>
                </div>
            </slot>
        </div>

        <div v-if="paginationValues" class="table-helper-footer" :class="footerClass">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>

            <div class="results-numbers" v-if="!hideRowCount">
                <span v-if="isFrench()">
                    Affichage de {{paginationValues.start}} sur {{paginationValues.end}} ({{totalRows}} total)
                </span>
                <span v-else>
                    Showing items {{paginationValues.start}} of {{paginationValues.end}} (of {{totalRows}} total)
                </span>
            </div>
            <slot name="footer"></slot>
        </div>

        <!-- Useful for having a unified modal for the entire table when e-table-helper is root template component -->
        <slot name="modals"></slot>
    </div>
</template>

<script>
    import {isFrench} from "../../vue-components/helpers/i18n";
    import download from "downloadjs";
    import ECheckMark from "./e-check-mark.vue";
    import EEmptyPlaceholder from "./e-empty-placeholder.vue";

    export default {
        name: `e-table-helper`,
        components: {ECheckMark, EEmptyPlaceholder},
        props: {
            title: {type: String, default: null},
            subtitle: {type: String, default: null},
            items: { type: Array, default: () => [] },
            fields: { type: Array, default: () => [] },
            filter: { type: String, default: `` },
            filterFunction: { type: Function, default: null },
            filterDebounce: { type: [Number, String], default: 150 },
            filterIgnoreFields: { type: Array, default: () => [] },
            filterIncludedFields: { type: Array, default: () => [] },
            footerClass: { type: String, default: `` },
            tableClass: { type: String, default: `` },
            containerClass: { type: String, default: `` },
            headerClass: { type: String, default: `` },
            headerVariant: { type: String, default: null },
            fullscreen: { type: Boolean },
            compact: { type: Boolean },
            hover: { type: Boolean },
            striped: { type: Boolean, default: true },
            stickyHeader: { type: [Boolean, String] }, // true or table max-height
            searchPlaceholder: { type: String, default: `Search|Rechercher` },
            exportLabel: { type: String, default: `Export to Excel|Exporter sur Excel` },
            hideExcel: { type: Boolean },
            hideSearch: { type: Boolean },
            hideRowCount: { type: Boolean },
            hideExportCSV: { type: Boolean },
            exportName: { type: String, default: `Export.csv` },
            defaultPerPage: { type: [String, Number], default: 20 },
            rowSizes: { type: Array, default: () => [5, 10, 15, 20, 25, 50, 100, 250] },
            emptyTitle: { type: String, default: `No Data|Aucune données` },
            emptyDescription: { type: String, default: `We couldn't find any data for your query.|Il n'y aucune données pour votre requête` },
            sortBy: { type: String, default: null },
            sortDirection: { type: String, default: `asc` },
            sortDesc: { type: Boolean },
            sortCompare: { type: Function, default: null },
            selectable: { type: Boolean, default: false },
            selectedVariant: { type: String, default: `` },
            selectMode: { type: String, default: `multi` }
        },
        data() {
            return {
                isFrench: isFrench,
                tableFields: [],
                selection: null,
                totalRows: 0,
                perPage: 10,
                internalFilter: ``,
                excelMode: false,
                currentPage: 1,
                columnsSelectorOpened: false
            }
        },
        computed: {
            headerButtonVariant() {
                return this.headerVariant === `light` ? `white` : `light`;
            },
            hasAnyItems() {
                return this.items && this.items.length > 0;
            },
            fieldsToShow() {
                return this.tableFields.filter(field => {
                    return !field.hidden && field.export !== false;
                });
            },
            resultTextReplaced() {
                return this.resultLabel.replace(`%f`, (this.currentPage - 1) * this.perPage + 1)
                    .replace(`%l`, this.currentPage * this.perPage)
                    .replace(`%t`, this.totalRows)
            },
            paginationValues() {
                if (this.items) {
                    return {
                        start: Math.min((this.currentPage - 1) * this.perPage + 1, this.items.length),
                        end: Math.min((this.currentPage) * this.perPage + 1, this.items.length)
                    }
                } else {
                    return null;
                }
            },
            isAllRowsSelected() {
                return this.selection && this.selection.length === this.items.length;
            }
        },
        mounted() {
            this.internalFilter = this.filter;
            this.tableFields = this.fields;
            this.perPage = this.defaultPerPage;
            this.totalRows = this.items ? this.items.length : 0;
        },
        methods: {
            toggleColumnsSelector() {
                this.columnsSelectorOpened = !this.columnsSelectorOpened;
            },
            exportToExcel() {
                let fieldsToExport = this.tableFields.filter(field => {
                    return field.export !== false;
                });

                let csv = ``;
                fieldsToExport.forEach(field => {
                    csv += field.label + `\t`;
                });

                this.items.forEach(item => {
                    csv += `\n`;
                    fieldsToExport.forEach(field => {
                        if (item.hasOwnProperty(field.key)) {
                            csv += item[field.key] + `\t`;
                        }
                    });
                });

                download(csv, this.exportName);
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            showColumn(key) {
                this.tableFields = this.tableFields.map(field => {
                    field.hidden = field.key === key ? !field.hidden : field.hidden;
                    return field;
                });
            },
            startExcelMode() {
                this.excelMode = true;
            },
            stopExcelMode() {
                this.excelMode = false;
            },
            onRowSelected(selection) {
                // if (this.internalFilter && selection && selection.length === 0) {
                //     // Event is triggered twice with filter, probably because the filtered event is triggered after selection
                // } else {
                this.selection = selection;
                    this.$emit(`row-selected`, selection);
                // }
            },
            toggleAllRows() {
                if (this.isAllRowsSelected) {
                    this.clearSelected();
                } else {
                    this.selectAllRows();
                }
            },
            selectAllRows() {
                this.perPage = this.items.length;
                this.$nextTick(() => {
                    this.$refs.tableHelper.selectAllRows();
                });
            },
            clearSelection() {
                this.clearSelected();
            },
            clearSelected() {
                this.$refs.tableHelper.clearSelected();
            },
            selectRow(index) {
                this.$refs.tableHelper.selectRow(index);
            },
            unselectRow(index) {
                this.$refs.tableHelper.unselectRow(index);
            },
            isSelected(item) {
                return this.selection && this.selection.indexOf(item) >= 0;
            }
        },
        watch: {
            items() {
                this.totalRows = this.items.length;
            },
            fields() {
                this.tableFields = this.fields;
            },
            filter() {
                this.internalFilter = this.filter;
            }
        }
    }
</script>

<style lang="scss" scoped>
    $excel-color: #227446;
    $header-height: 64px;
    $footer-height: 31px;

    .table-helper {
        background-color: white;
        color: #333;
        border-color: #ccc;
        border-radius: 5px;

        ::v-deep thead th {
            border-bottom-width: 1px;
        }

        .table-helper-table-container {
            width: 100%;
            overflow-y: auto;
        }

        > .table-helper-header {
            padding: 7px 14px;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;

            .search {
                float: right;
                position: relative;
                margin-right: -10px;
                margin-left: 10px;
                width: 250px;

                @media screen and (max-width: 576px) {
                    width: 100%;
                    right: 0;
                }

                input {
                    padding-right: 30px;
                }

                .fas {
                    position: absolute;
                    top: 9px;
                    right: 10px;
                    font-size: 16px;
                    color: #aaa;

                    &:hover {
                        color: #999;
                    }
                }
            }

            .actions-bar {
                flex: 1;

                //display: flex;
                //left: 5px;
                //top: 5px;
                //position: relative;
                //width: 100%;

                .input-group {
                    width: auto;

                    select {
                        max-width: 80px;
                    }
                }
            }

            .title {
                margin: 5px 10px 5px 0;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 500;
                display: inline-flex;

                a.interactive {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                @media(max-width: 576px) {
                    display: none;
                }
            }

            .excel-button {
                float: right;
                height: 45px;
                width: 45px;
                font-size: 18px;
                cursor: pointer;
                border: 2px solid $excel-color !important;
                border-radius: 50%;
                color: $excel-color !important;

                &:hover {
                    background-color: $excel-color;
                    color: white !important;
                }
            }

            .close-button {
                position: absolute;
                top: 0;
                right: 0;
                height: $header-height !important;
                width: $header-height !important;
                box-shadow: none;
                border: none;
                font-size: 20px;
                background: transparent;
                border-radius: 0;
                color: white !important;

                &:hover {
                    background: rgba(255, 255, 255, 0.2);
                }
            }
        }

        .multi-selection-bar {
            padding: 7px 14px;
            display: flex;
            align-items: center;
            margin-top: -1px;
            border-top: 1px solid #74b0c2;
            background-color: #b3e1ee;
            border-bottom: 1px solid #74b0c2;

            .multi-selection-actions,
            .multi-selection-summary {
                display: flex;
                align-items: center;
                vertical-align: middle;

                > * {
                    vertical-align: middle;
                }
            }
        }

        > .table-helper-footer {
            display: flex;
            align-items: center;
            padding: 0 10px 10px;

            .results-numbers {
                border: 1px solid #EEE;
                display: inline-flex;
                padding: 7px 14px;
                font-size: 12px;
                margin-left: auto;
                color: #555;
            }

            ::v-deep .pagination {
                display: inline-flex;
                ul {
                    margin: 0;
                }

                .page-item {
                    .page-link {
                        font-weight: 500;
                    }
                    .active .page-link {
                        background-color: #00bcd4;
                        border: none;
                    }
                }
            }
        }

        &.compact {
            ::v-deep .table td,
            ::v-deep .table th {
                padding: 0.5rem 0.6rem !important;
            }
        }

        &.excel-mode {
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;

            .excel-mode-actions {
                float: left;

                .excel-icon {
                    width: 48px;
                    height: 48px;
                    margin-right: 10px;
                    background-size: cover;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    font-size: 24px;
                    line-height: 48px;
                    background-image: url(https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/ico_excel.png);
                }
            }

            .table-helper-header {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                background-color: $excel-color !important;
                color: white;
                height: $header-height;
                border-radius: 0;

                .actions-bar {
                    padding-top: 5px;
                    padding-right: 60px;
                    //margin-left: 10px;
                    //top: 15px;
                    //width: 450px;

                    .input-group {
                        .btn,
                        .form-control,
                        .custom-select {
                            color: #333;
                            border: 1px solid #ced4da;
                            background-color: white;
                        }
                    }
                }
            }

            .table-helper-table-container {
                position: absolute;
                top: $header-height;
                bottom: $footer-height;
                left: 0;
                right: 0;
                background-color: white;
            }

            .table-helper-footer {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: #227446;
                color: white;
                height: $footer-height;
                align-items: flex-start;

                ::v-deep .pagination {
                    margin: 0 !important;

                    .page-item {
                        width: 40px;
                        height: $footer-height;

                        .page-link {
                            background-color: $excel-color !important;
                            border-radius: 0 !important;
                            color: white !important;
                            border: 0;
                            margin: 0;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            border-right: 1px solid rgba(255, 255, 255, 0.2);

                            &:enabled {
                                cursor: pointer;
                            }

                            &:before {
                                border-radius: 0 !important;
                            }

                            &:hover {
                                background-color: darken($excel-color, 5%) !important;
                                border-radius: 0 !important;
                            }
                        }

                        &.active .page-link {
                            background-color: lighten($excel-color, 8%) !important;
                        }
                    }
                }

                .results-numbers {
                    color: white;
                    background-color: rgba(255, 255, 255, 0.1);
                    border: none;
                    height: $footer-height;
                    line-height: $footer-height;
                    padding: 0 30px;
                    font-size: 12px;
                }
            }
        }

        &:not(.excel-mode) {
            .table-helper-header.bg-light {
                ::v-deep .btn.btn-white:not(:hover) {
                    background: white;
                    border-color: #ced4da;
                }
            }
        }

        &.fullscreen:not(.excel-mode) {
            display: flex;
            flex-flow: row wrap;
            flex-direction: column;
            height: 100%;

            .table-helper-footer,
            .table-helper-header {
                flex: 1;
            }

            .table-helper-header {
                .actions-bar {
                    //padding-top: 8px;
                }
            }

            > .b-table,
            .table-responsive {
                overflow-x: hidden;
                overflow-y: visible;
                background: transparent;
                min-height: 400px;
                width: 100%;
                margin: 0;
            }

            .table-helper-table-container {
                flex: 30;
                overflow-x: hidden;
                overflow-y: auto;
                background: transparent;
            }

            .b-table {
                display: initial !important;
            }

            ul.pagination {
                margin-top: 5px;
                margin-bottom: 0;
            }
        }
    }
</style>
