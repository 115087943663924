import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store.js";

import licenses from './views/licenses.vue';
import license from './views/license.vue';
import licensePackages from './views/packages.vue';
import licensePackage from './views/package.vue';
import agreementSignature from './views/agreement-signature.vue';

Vue.use(VueRouter);

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                } else {
                    const redirectURL = to ? (window.location.href + `#` + to.path) : window.location.href;
                    window.location.href = window.env.VUE_APP_URLS__LOGIN + `/?redirect_url=` + encodeURIComponent(redirectURL);
                }
            });
    }
}

const routes = [
    {
        path: `/`,
        name: `home`,
        alias: `/licenses`,
        component: licenses,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/license/:id`,
        name: `license`,
        component: license,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/packages`,
        name: `packages`,
        component: licensePackages,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/package/:id`,
        name: `package`,
        component: licensePackage,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/agreement-signature/:id`,
        name: `agreement-signature`,
        component: agreementSignature
    }
];

const router = new VueRouter({
    mode: `history`,
    routes: routes
});

export default router;
